import {
  type AskForPasswordResetDto,
  type PasswordResetDto,
  type UserByIdDto,
  type CreateVirtualUserDto,
  type EditClusterMemberRolesDto,
  type ResendEmailInviteDto,
  type SignupDto,
  type contract,
  type Override,
  type AnyObject,
  ASSET_TAG_FILTER_PREFIX
} from '@samsys/shared';
import type { TsRestClient } from './ts-rest.service';
import {
  apiHandler,
  type InferBody,
  type InferFlatRequest,
  type InferParams,
  type InferQuery
} from '@/utils/ts-rest';

type WithoutTagFilter<T extends { filter: AnyObject }> = Override<
  T,
  {
    filter: Omit<T['filter'], 'tags'>;
  }
>;

export const createUserService = ({
  tsRestClient
}: {
  tsRestClient: TsRestClient;
}) => {
  return {
    askForPasswordReset(body: AskForPasswordResetDto) {
      return apiHandler(tsRestClient.auth.forgotPassword, { body });
    },

    resetPassword(body: PasswordResetDto) {
      return apiHandler(tsRestClient.auth.resetPassword, { body });
    },

    getById({ userId }: UserByIdDto) {
      return apiHandler(tsRestClient.user.byId, { params: { userId } });
    },

    editProfile({
      userId,
      ...body
    }: InferFlatRequest<typeof contract.user.editProfile>) {
      return apiHandler(tsRestClient.user.editProfile, {
        params: { userId },
        body
      });
    },

    editTags({
      userId,
      ...body
    }: InferFlatRequest<typeof contract.user.editTags>) {
      return apiHandler(tsRestClient.user.editTags, {
        params: { userId },
        body
      });
    },

    getAvailableHashtags() {
      return apiHandler(tsRestClient.user.hashtags, {});
    },

    editHashtags({
      userId,
      ...body
    }: InferFlatRequest<typeof contract.user.editHashtags>) {
      return apiHandler(tsRestClient.user.editHashtags, {
        params: { userId },
        body
      });
    },

    getAll(query: WithoutTagFilter<InferQuery<typeof contract.user.all>>) {
      const normalizedFilters: InferQuery<typeof contract.user.all>['filter'] =
        {
          tags: {}
        };
      for (const [name, filter] of Object.entries(query.filter)) {
        if (!normalizedFilters.tags) {
          normalizedFilters.tags = {};
        }

        if (name.startsWith(ASSET_TAG_FILTER_PREFIX)) {
          const key = name.replace(ASSET_TAG_FILTER_PREFIX, '');
          normalizedFilters.tags[key] = filter;
        } else {
          // @ts-expect-error object key indexing issue
          normalizedFilters[name] = filter;
        }
      }

      return apiHandler(tsRestClient.user.all, {
        query: { ...query, filter: normalizedFilters }
      });
    },

    getList(query: InferQuery<typeof contract.user.list>) {
      return apiHandler(tsRestClient.user.list, { query });
    },

    createVirtual(body: CreateVirtualUserDto) {
      return apiHandler(tsRestClient.user.createVirtual, { body });
    },

    getAvailableTags() {
      return apiHandler(tsRestClient.user.tags, {});
    },

    updateTags({
      userId,
      ...body
    }: InferFlatRequest<typeof contract.user.editTags>) {
      return apiHandler(tsRestClient.user.editTags, {
        params: { userId },
        body
      });
    },

    async updateRights({
      userId,
      rights,
      retroactive
    }: {
      userId: string;
      rights: Record<
        InferParams<typeof contract.user.editclusterMemberRights>['clusterId'],
        InferBody<typeof contract.user.editclusterMemberRights>['rules']
      >;
      retroactive?: boolean;
    }) {
      const requests = Object.entries(rights).map(([clusterId, rules]) => {
        return apiHandler(tsRestClient.user.editclusterMemberRights, {
          params: { userId, clusterId },
          body: { rules, retroactive }
        });
      });

      return Promise.allSettled(requests);
    },

    async updateRoles({
      userId,
      roles
    }: {
      userId: string;
      roles: Record<
        EditClusterMemberRolesDto['clusterId'],
        EditClusterMemberRolesDto['roles']
      >;
    }) {
      const requests = Object.entries(roles).map(([clusterId, roles]) => {
        return apiHandler(tsRestClient.user.editclusterMemberRoles, {
          params: { userId, clusterId },
          body: { roles }
        });
      });

      return Promise.allSettled(requests);
    },

    async resendInviteEmail({ userId }: ResendEmailInviteDto) {
      return apiHandler(tsRestClient.user.resendInviteEmail, {
        params: { userId }
      });
    },

    changePassowrd({
      userId,
      ...body
    }: InferFlatRequest<typeof contract.user.changePassword>) {
      return apiHandler(tsRestClient.user.changePassword, {
        params: { userId },
        body
      });
    },

    signup(body: SignupDto) {
      return apiHandler(tsRestClient.user.signup, { body });
    },

    export(opts: InferBody<typeof contract.user.export>) {
      const normalizedFilters: InferBody<
        typeof contract.user.export
      >['filter'] = {
        tags: {}
      };
      for (const [name, filter] of Object.entries(opts.filter)) {
        if (name.startsWith(ASSET_TAG_FILTER_PREFIX)) {
          const key = name.replace(ASSET_TAG_FILTER_PREFIX, '');
          // @ts-expect-error object key indexing issue
          normalizedFilters.tags[key] = filter;
        } else {
          // @ts-expect-error object key indexing issue
          normalizedFilters[name] = filter;
        }
      }

      return apiHandler(tsRestClient.user.export, {
        body: { ...opts, filter: normalizedFilters }
      });
    },

    getSchedule(params: InferParams<typeof contract.user.schedule>) {
      return apiHandler(tsRestClient.user.schedule, { params });
    }
  };
};
