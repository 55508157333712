<script setup lang="ts">
import { queryKeys } from './utils/queryKeys';
import logo from '@/assets/logo.png';

useFeatureFlagsGuard();
useAuthGuard();

const isOnline = useOnline();
const { showInfo, showDanger, clearAll } = useToast();

const { t } = useI18n();
watch(isOnline, (isOnline, prevIsOnline) => {
  if (isOnline && !prevIsOnline) {
    clearAll();
    return showInfo({
      title: t('network.toasts.online.title'),
      text: t('network.toasts.online.text'),
      placement: 'bottom',
      timeout: 10_000
    });
  }
  if (!isOnline) {
    showDanger({
      title: t('network.toasts.offline.title'),
      text: t('network.toasts.offline.text'),
      placement: 'bottom',
      timeout: false
    });
  }
});

const api = useApi();
const qc = useQueryClient();

const error = useErrorCatcher();

const onClear = async () => {
  try {
    await Promise.all([
      api.authService.init(),
      qc.prefetchQuery(
        queryKeys.FEATURE_FLAGS,
        api.featureFlagService.getFeatureFlags
      )
    ]);
  } finally {
    error.value = null;
  }
};
const { data: ff } = useFeatureFlagsQuery();
</script>

<template>
  <ErrorBoundary :error="error" @clear="onClear">
    <div v-if="!ff" class="ff-loader">
      <UiSurface>
        <img :src="logo" alt="logo" />

        <UiSpinner size="xl" />
      </UiSurface>
    </div>
    <Suspense v-else>
      <DynamicLayout>
        <UiToastBar />

        <UiAnimatedRouterView />
      </DynamicLayout>

      <template #fallback>
        <UiCenter class="fullpage-loader">
          <UiSpinner size="xl" />
        </UiCenter>
      </template>
    </Suspense>
  </ErrorBoundary>

  <ServiceWorkerPrompt />
</template>

<style scoped lang="postcss">
@import '@/styles/medias.css';

.error-boundary {
  height: 100vh;
  height: 100dvh;

  & > * {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: var(--size-4);
  }
  & .ui-icon {
    color: var(--error);
  }
}

.fullpage-loader {
  height: 100vh;
  height: 100dvh;
}

.ff-loader {
  min-height: 100vh;
  min-height: 100dvh;
  background: linear-gradient(135deg, var(--yellow-3), var(--lime-8));
  padding-inline: var(--size-2);
  display: grid;
  place-content: center;

  > div {
    display: grid;
    justify-items: center;
  }
  img {
    width: 20rem;
    margin-bottom: var(--size-6);
  }
}
</style>
