import type { contract } from '@samsys/shared';
import type { TsRestClient } from './ts-rest.service';
import {
  apiHandler,
  type InferBody,
  type InferFlatRequest,
  type InferQuery
} from '@/utils/ts-rest';

export type CreatePlantProtectionProductServiceOptions = {
  tsRestClient: TsRestClient;
};

export const createPlantProtectionProductService = ({
  tsRestClient
}: CreatePlantProtectionProductServiceOptions) => {
  return {
    getAll(query: InferQuery<typeof contract.plantProtectionProduct.all>) {
      return apiHandler(tsRestClient.plantProtectionProduct.all, {
        query
      });
    },

    getById(id: string) {
      return apiHandler(tsRestClient.plantProtectionProduct.byId, {
        params: { id }
      });
    },

    getAllMixes(
      query: InferQuery<typeof contract.plantProtectionProduct.allMixes>
    ) {
      return apiHandler(tsRestClient.plantProtectionProduct.allMixes, {
        query
      });
    },

    createMix(
      body: InferBody<typeof contract.plantProtectionProduct.createMix>
    ) {
      return apiHandler(tsRestClient.plantProtectionProduct.createMix, {
        body
      });
    },

    deleteMix({
      id
    }: InferFlatRequest<typeof contract.plantProtectionProduct.deleteMix>) {
      return apiHandler(tsRestClient.plantProtectionProduct.deleteMix, {
        params: { id }
      });
    },

    updateMix({
      id,
      ...body
    }: InferFlatRequest<typeof contract.plantProtectionProduct.updateMix>) {
      return apiHandler(tsRestClient.plantProtectionProduct.updateMix, {
        body,
        params: { id }
      });
    }
  };
};
